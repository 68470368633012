<template lang="pug">
  div(style="max-width: 540px; margin: auto")
    h1 {{$t('musics.title')}} ({{sortedMusics.length}})
    .py-2

    h3 {{$t('filter')}}
      v-btn.float-right.mt-n2(icon, :to="{ path: $route.path }", v-if="Object.keys($route.query).length", exact)
        v-icon(small) mdi-backspace

    Divider
    v-list.py-0(dense)
      template(v-for="difficulty, i in ['expert', 'master']")
        Divider(v-if="i", inset=16)
        v-list-item
          template(v-for="level, i in levelSummaries[difficulty]")
            .pr-1(v-if="i")
            v-list-item.px-0(dense, :to="{ query: filterQuery({ [`musicDifficulties.${difficulty}.playLevel`]: level }) }", exact)
              v-list-item-subtitle
                MusicDifficulty(:level="level", :difficulty="difficulty", :disable="(filter[`musicDifficulties.${difficulty}.playLevel`] || []).indexOf(level.toString()) == -1")

      Divider(inset=16)
      v-menu(offset-x, nudge-left=256+8, nudge-bottom=40, max-width=256, :close-on-content-click="false")
        template(v-slot:activator="{on, attrs}")
          v-list-item(v-on="on", v-bind="attrs")
            v-list-item-content
              v-list-item-title {{$t('musics.genre')}}
              v-list-item-subtitle
                template(v-for="genre, i in filter[`music.genre`] || []")
                  template(v-if="i") {{$t('comma')}}
                  template {{genres.find(g => g.genre == genre).unit ? $db.unitProfiles[genres.find(g => g.genre == genre).unit].unitName : $t('others')}}
            v-icon mdi-chevron-down

        v-list.py-0(dense)
          template(v-for="genre, i in genres")
            Divider(v-if="i", inset=56)
            v-list-item(:to="{ query: filterQuery({ [`music.genre`]: genre.genre }) }", exact)
              v-list-item-icon.mr-4
                v-icon(color="primary", v-if="(filter[`music.genre`] || []).indexOf(genre.genre) != -1") mdi-check
              v-list-item-content
                v-list-item-title
                  template(v-if="genre.unit") {{$db.unitProfiles[genre.unit].unitName}}
                  template(v-else) {{$t('others')}}

    Divider
    .py-2

    Divider
    v-list.py-0(dense)
      v-list-item
        v-menu(offset-y)
          template(v-slot:activator="{on, attrs}")
            div(style="width: 40px").mr-4
              v-list-item.px-0(v-on="on", v-bind="attrs", )
                v-icon.ma-auto mdi-sort

          v-card.d-flex(elevation=24)
            v-list.py-0(dense)
              v-list-item
                v-list-item-subtitle {{$t('musics.musicInformation')}}
              Divider
              template(v-for="sort, i in defaultSortFunctions.filter(sort => !sort.difficulty)")
                Divider(inset=56, v-if="i")
                v-list-item(:to="{ query: sortQuery({ function: sort.function, order: sort.order, difficulty: undefined }) }", exact)
                  v-icon.pr-4 mdi-chevron-{{sort.order > 0 ? 'up' : 'down'}}
                  v-list-item-title {{$t(sort.name)}}
              Divider

            v-divider(vertical)
        
            v-list.py-0(dense)
              v-list-item
                v-list-item-subtitle {{$t('musics.difficulties')}}
              Divider
              template(v-for="sort, i in defaultSortFunctions.filter(sort => sort.difficulty)")
                Divider(inset=56, v-if="i")
                v-list-item(:to="{ query: sortQuery({ function: sort.function, order: sort.order, difficulty: sort.difficulty }) }", exact)
                  v-icon.pr-4 mdi-chevron-{{sort.order > 0 ? 'up' : 'down'}}
                  v-list-item-title {{$t(sort.name)}}
              Divider
              v-list-item(:to="{ query: sortQuery({ order: sort.order > 0 ? -1: 1 }) }", exact)
                v-icon.pr-4 mdi-sort-{{sort.order > 0 ? 'ascending' : 'descending'}}
                v-list-item-title {{$t('reverse')}}
              Divider

        div(style="width: calc(100% - 56px)")
          .d-flex(style="height: 40px; position: absolute;")
            v-list-item-subtitle.my-auto
              span(v-if="!sort.difficulty") {{defaultSortFunction ? $t(defaultSortFunction.name) : ''}}
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item.px-0(dense, style="width: calc(20% - 3.2px); z-index: 1"
                :to="{ query: sortQuery(sort.difficulty ? { order: (sort.difficulty == difficulty) ^ (sort.order > 0) ? 1 : -1, difficulty: sort.difficulty && difficulty } : { function: 'musicDifficulty.playLevel+(musicDifficulty.playLevelAdjust||0)', order: -1, difficulty: difficulty }) }", exact)
                .ma-auto(v-if="sort.difficulty")
                  v-icon(v-if="difficulty == sort.difficulty") mdi-chevron-{{sort.order > 0 ? 'up' : 'down'}}

      Divider

      template(v-for="music, i in sortedMusics")
        Divider(v-if="i", inset=72)
        Music(:id="music.id")

    Divider

    .py-2

</template>

<script>
import Music from './Music';
import defaultSortFunctions from './defaultSortFunctions';
import MusicDifficulty from '@/components/MusicDifficulty';

export default {
  name: 'Musics',

  components: { Music, MusicDifficulty },

  data() {
    return {
      defaultSortFunctions,
    };
  },

  methods: {
    defaultQuery() {
      let query = {};
      for (let key in this.filter) {
        query[key] = Array.from(this.filter[key]);
      }
      for (let key in this.sort) {
        query[`sort.${key}`] = this.sort[key];
      }
      return query;
    },

    filterQuery(filter) {
      let query = this.defaultQuery();

      for (let key in filter) {
        let value = filter[key]?.toString();

        if (query[key]) {
          let index = query[key].indexOf(value);
          if (index == -1) {
            query[key].push(value);
          } else {
            query[key].splice(index, 1);
          }
        } else {
          query[key] = [value];
        }
      }

      return query;
    },

    sortQuery(sort) {
      let query = this.defaultQuery();

      if (sort.difficulty === true) {
        sort.difficulty = this.sort.difficulty || 'master';
      }

      for (let key in sort) {
        query[`sort.${key}`] = sort[key];
      }
      return query;
    },
  },

  computed: {
    filter() {
      let filter = {};
      for (let key in this.$route.query) {
        if (key.startsWith('sort.')) continue;
        if (Array.isArray(this.$route.query[key])) {
          filter[key] = this.$route.query[key].slice();
        } else {
          filter[key] = [this.$route.query[key]];
        }
      }
      return filter;
    },

    sort() {
      let sort = {};
      for (let key in this.$route.query) {
        if (!key.startsWith('sort.')) continue;
        sort[key.slice(5)] = this.$route.query[key];
      }
      return sort;
    },

    sortFunction() {
      if (this.sort.function) {
        return (music, difficulty) => {
          // eslint-disable-next-line
          let musicDifficulty = this.$db.musicDifficulties[music.id][difficulty || this.sort.difficulty];

          return eval(this.sort.function);
        };
      }

      return undefined;
    },

    defaultSortFunction() {
      return defaultSortFunctions.find(defaultSortFunction => defaultSortFunction.function == this.sort.function);
    },

    sortedMusics() {
      let musics = Object.values(this.$db.musics).filter(music => {
        if (music.publishedAt > new Date().valueOf()) return false;

        for (let key in this.filter) {
          if (!this.filter[key].length) continue;

          // eslint-disable-next-line
          music;
          // eslint-disable-next-line
          let musicDifficulties = this.$db.musicDifficulties[music.id];

          let value = eval(key);
          if (!this.filter[key].some(query => query == value)) return false;
        }
        return true;
      });

      if (this.sortFunction) {
        musics.sort((a, b) => {
          a = this.sortFunction(a);
          b = this.sortFunction(b);
          if (a > b) return this.sort.order;
          if (a < b) return -this.sort.order;
          return 0;
        });
      }

      return musics;
    },

    levelSummaries() {
      let levelSummaries = {
        easy: new Set(),
        normal: new Set(),
        hard: new Set(),
        expert: new Set(),
        master: new Set(),
      };
      for (let music of Object.values(this.$db.musics)) {
        for (let musicDifficulty of Object.values(this.$db.musicDifficulties[music.id])) {
          levelSummaries[musicDifficulty.musicDifficulty].add(musicDifficulty.playLevel);
        }
      }
      for (let difficulty in levelSummaries) {
        levelSummaries[difficulty] = Array.from(levelSummaries[difficulty]).sort();
      }
      return levelSummaries;
    },

    genres() {
      return [
        { unit: 'piapro', genre: 'vocaloid' },
        { unit: 'light_sound', genre: 'light_music_club' },
        { unit: 'idol', genre: 'idol' },
        { unit: 'street', genre: 'street' },
        { unit: 'theme_park', genre: 'theme_park' },
        { unit: 'school_refusal', genre: 'school_refusal' },
        { unit: undefined, genre: 'other' },
      ];
    }
  },
};
</script>
